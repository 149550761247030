import { defineComponent } from 'vue';
import { Pregame, Stoppedgame, Testgame, Postgame } from '@/components/Widgets/GameStates';
import { Timer, Level, FullscreenMessage, MessageBar, IntroductionSlide } from '@/components/Widgets';
import { Animation } from '@/Animation';
import './RoomGrid.scss';
import useTranslations from '@/compositions/useTranslations';
import LevelStatus from '@/components/Widgets/LevelStatus/LevelStatus.vue';
import InfoBar from '@/components/Widgets/InfoBar/InfoBar.vue';
export default defineComponent({
    name: 'GridRoom',
    components: {
        Pregame,
        Postgame,
        Stoppedgame,
        Testgame,
        Timer,
        Level,
        FullscreenMessage,
        MessageBar,
        IntroductionSlide,
        LevelStatus,
        InfoBar
    },
    props: {
        roomid: {
            type: String,
            required: true
        }
    },
    setup: () => ({
        ...useTranslations()
    }),
    data: () => ({
        animation: Animation,
    }),
    computed: {
        gameMessage() {
            return '';
        },
        gameState() {
            return this.$store.direct.state.rooms.gameState;
        },
        level() {
            return this.$store.direct.state.rooms.level + 1;
        },
        score() {
            return this.$store.direct.state.rooms.score;
        },
        clockrunning() {
            return this.$store.direct.state.rooms.clockRunning;
        },
        timeleft() {
            return this.$store.direct.state.rooms.runningTimer;
        },
        mistakesLeft() {
            return this.$store.direct.state.rooms.mistakesLeft;
        },
        ingameStatus() {
            return this.$store.direct.state.rooms.ingameStatus;
        },
        gamestate() {
            return this.$store.direct.state.rooms.gameState;
        },
        status() {
            return this.$store.direct.state.rooms.status[this.roomid];
        },
        config() {
            return this.$store.direct.state.rooms.config[this.roomid];
        },
        remainingTiles() {
            return this.status.active_tiles.length;
        }
    },
    watch: {
        level(newValue, oldValue) {
            if (oldValue !== newValue && this.gamestate === 'playing') {
                Animation.animateLevelChange(() => { });
                setTimeout(() => { Animation.animateLevel('.level .level_icon', () => { }); }, 3000);
            }
        },
        mistakesLeft(newValue, oldValue) {
            if (this.gamestate === 'playing' && newValue < oldValue) {
                new Audio(require('@/assets/sound/brokenglass.mp3')).play();
            }
        }
    },
    methods: {
        onEvent(eventType, data) {
            console.log('NEW EVENT OF TYPE ' + eventType + ':', data);
            if (eventType === 'clock' && data.status === 'start') {
                new Audio(require('@/assets/sound/go.mp3')).play();
            }
            else if (eventType === 'pause_upcoming' && data.status === 'play') {
                new Audio(require('@/assets/sound/countdown.mp3')).play();
            }
            else if (eventType === 'score' && data.status === 'up') {
                new Audio(require('@/assets/sound/correct.mp3')).play();
            }
            else if (eventType === 'clock' && data.status === 'stopped') {
                new Audio(require('@/assets/sound/pauze.mp3')).play();
            }
        }
    }
});
