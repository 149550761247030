import { defineComponent } from 'vue';
import Grid from './Grid.vue';
export default defineComponent({
    name: 'GridRoomControl',
    components: {
        Grid
    },
    props: {
        roomid: {
            type: String,
            required: true
        }
    },
    computed: {
        status() {
            return this.$store.direct.state.rooms.status[this.roomid];
        },
        pattern() {
            var _a;
            return ((_a = this.status.sensors.find(sensor => sensor.name === 'grid')) === null || _a === void 0 ? void 0 : _a.status) || '';
        },
        config() {
            return this.$store.direct.state.rooms.config[this.roomid];
        },
    }
});
