import { defineComponent } from 'vue';
export default defineComponent({
    props: {
        roomid: {
            type: String,
            required: true
        },
        debug: {
            type: Boolean,
            default: false
        },
        config: {
            type: Object,
            required: true
        },
        pattern: {
            type: String,
            default: '',
            required: true
        },
        disabled_indexes: {
            type: Array,
            default: () => [],
            required: false
        },
        allowControl: {
            type: Boolean,
            default: false,
            required: false
        }
    },
    computed: {
        rows() {
            var _a, _b;
            return Array.from({ length: (_b = (_a = this.config) === null || _a === void 0 ? void 0 : _a.grid_width) !== null && _b !== void 0 ? _b : 1 }, (_, i) => i);
        },
        columns() {
            var _a, _b;
            return Array.from({ length: (_b = (_a = this.config) === null || _a === void 0 ? void 0 : _a.grid_height) !== null && _b !== void 0 ? _b : 1 }, (_, i) => i);
        }
    },
    methods: {
        tileClick(row, col) {
            var _a;
            if (this.config === null) {
                return;
            }
            console.log('Tile Clicked');
            const tileNr = col * ((_a = this.config) === null || _a === void 0 ? void 0 : _a.grid_width) + row;
            const parameters = {
                roomid: this.roomid,
                button: 'grid_' + tileNr,
                value: 'clicked'
            };
            this.$store.direct.dispatch.rooms.setButton(parameters);
            setTimeout(() => {
                parameters.value = 'released';
                this.$store.direct.dispatch.rooms.setButton(parameters);
            }, 200);
        },
        getTileColor(row, col) {
            var _a;
            if (this.pattern == undefined || this.config === null) {
                return '';
            }
            const tileNr = col * ((_a = this.config) === null || _a === void 0 ? void 0 : _a.grid_width) + row;
            let disabled = '';
            if (this.disabled_indexes.includes(tileNr)) {
                disabled += 'disabled';
            }
            return (this.pattern.charAt(tileNr) || '') + ' ' + disabled;
        },
        clickRandomTiles(clicksPerSecond, numberOfTotalClicks) {
            for (let i = 0; i < numberOfTotalClicks; i++) {
                setTimeout(() => {
                    this.clickRandomTile();
                }, (1000) / clicksPerSecond);
            }
        },
        clickRandomTile() {
            const randomTileRow = this.config ? Math.floor(Math.random() * this.config.grid_height) : 0;
            const randomTileCol = this.config ? Math.floor(Math.random() * this.config.grid_width) : 0;
            if (this.getTileColor(randomTileRow, randomTileCol) !== 'R') {
                this.tileClick(randomTileRow, randomTileCol);
            }
        }
    }
});
