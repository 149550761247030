import { defineComponent } from 'vue';
import EditRoom from '../EditRoom';
import RoomInfo from '../RoomInfo';
import RoomDebug from '../RoomDebug';
import { WebRTCPlayer } from '@/components/Widgets';
import { RoomControl } from '@/components/Rooms/Room';
import { GameState, GameType } from '@/types/rooms';
import { useToast } from 'vue-toastification';
import { ModalBox, DropDownMenu, Card } from '@/components/UI';
import { cloneDeep } from 'lodash';
import './RoomSummary.scss';
export default defineComponent({
    name: 'RoomSummary',
    components: {
        RoomInfo,
        EditRoom,
        RoomDebug,
        RoomControl,
        WebRTCPlayer,
        ModalBox,
        DropDownMenu,
        Card
    },
    props: {
        roomid: {
            type: String,
            required: true
        }
    },
    setup: () => ({
        toast: useToast()
    }),
    data: () => ({
        modalClass: '',
        copyConfig: null,
        currentStatus: null,
        showRestartRoom: false,
        showDeleteRoom: false,
        showControlRoom: false,
        showRoomInfo: false,
        showSensors: false
    }),
    computed: {
        items() {
            const items = [
                { id: 'control', icon: 'fa-gamepad', label: 'Game assist', action: () => this.showControlRoom = true },
                { id: 'debug', icon: 'fa-lightbulb', label: 'Sensors', action: () => this.showSensors = true },
                { id: 'edit', icon: 'fa-edit', label: 'Edit', action: () => {
                        this.copyConfig = cloneDeep(this.config);
                        this.modalClass = 'Edit Room';
                    } },
                { id: 'gamescreen', icon: 'fa-tv', label: 'Gamescreen',
                    // @ts-ignore: Object is possibly 'null'.
                    action: () => window.open('/room/' + this.roomid, '_blank').focus() },
                { id: 'info', icon: 'fa-info-circle', label: 'Info', action: () => this.showRoomInfo = true },
                { id: 'restart', icon: 'fa-sync', label: 'Restart', action: () => this.showRestartRoom = true }
            ];
            return items;
        },
        status() {
            return this.currentStatus;
        },
        config() {
            var _a;
            return (_a = this.$store.direct.state.rooms.config[this.roomid]) !== null && _a !== void 0 ? _a : null;
        },
        selectedButton() {
            var _a;
            if (((_a = this.status) === null || _a === void 0 ? void 0 : _a.type) === GameType.Reaction) {
                return this.status.selected_button;
            }
            return undefined;
        }
    },
    mounted() {
        console.log('RoomSummary mounting ' + this.roomid);
        this.$store.direct.dispatch.rooms.subscribeStatus(this.roomid).then(() => {
            var _a;
            this.currentStatus = (_a = this.$store.direct.state.rooms.status[this.roomid]) !== null && _a !== void 0 ? _a : null;
        }).catch(error => {
            console.error(error);
        });
        this.$store.direct.dispatch.rooms.subscribe(this.roomid);
    },
    unmounted() {
        console.log('RoomSummary unmounting ' + this.roomid);
        this.$store.direct.dispatch.rooms.unsubscribeStatus(this.roomid);
        this.$store.direct.dispatch.rooms.unsubscribe(this.roomid);
    },
    methods: {
        menuItemClicked(id) {
            var _a;
            (_a = this.items.find(item => item.id === id)) === null || _a === void 0 ? void 0 : _a.action();
        },
        // TOMC: FIX MODALS
        openSensors() {
            this.showSensors = true;
            this.modalClass = 'Debugging';
        },
        openControl() {
            this.showControlRoom = true;
        },
        time() {
            if (this.status === null || this.status.state === GameState.Stopped) {
                return 'Stopped';
            }
            const time = this.status.timeleft || 0;
            let secondsleft = Math.floor(time);
            const minutesleft = Math.floor(secondsleft / 60);
            secondsleft %= 60;
            return ('00' + minutesleft).slice(-2) + ':' + ('00' + secondsleft).slice(-2);
        },
        async restartRoom() {
            var _a;
            try {
                await this.$store.direct.dispatch.rooms.reboot(this.roomid);
                if (this.config === null)
                    throw new Error('Config doest not exists');
                this.toast.success(`Room ${this.config.name} is restarting.`);
            }
            catch (error) {
                this.toast.error(`Room ${(_a = this.config) === null || _a === void 0 ? void 0 : _a.name} is not restarting, ${error}`);
            }
            this.showRestartRoom = false;
        },
        async updateRoomConfig() {
            var _a, _b;
            if (this.copyConfig === null) {
                this.toast.error(`Room was not updated`);
                return;
            }
            try {
                await this.$store.direct.dispatch.rooms.update(this.copyConfig);
                this.toast.success(`Room ${(_a = this.copyConfig) === null || _a === void 0 ? void 0 : _a.name} has been updated`);
            }
            catch (error) {
                this.toast.error(`Room ${(_b = this.copyConfig) === null || _b === void 0 ? void 0 : _b.name} was not updated ${error}`);
            }
            this.modalClass = '';
        },
        async deleteRoom() {
            var _a, _b, _c, _d;
            if (((_a = this.config) === null || _a === void 0 ? void 0 : _a._id) === undefined) {
                this.toast.error(`Room ${(_b = this.config) === null || _b === void 0 ? void 0 : _b.name} has no id`);
                return;
            }
            try {
                await this.$store.direct.dispatch.rooms.delete(this.config._id);
                this.toast.success(`Room ${(_c = this.config) === null || _c === void 0 ? void 0 : _c.name} has been deleted`);
            }
            catch (error) {
                this.toast.error(`Room ${(_d = this.config) === null || _d === void 0 ? void 0 : _d.name} was not deleted ${error}`);
            }
        },
        updateLed(name, value) {
            this.$store.direct.dispatch.rooms.setLed({ led: name, roomid: this.roomid, value });
        },
        updateLedStrip(name, pattern) {
            this.$store.direct.dispatch.rooms.setLedStrip({ roomid: this.roomid, pattern, ledstrip: name });
        },
        updateSpiderWeb(name, value) {
            console.log('HERE', name, value);
            this.$store.direct.dispatch.rooms.setSpiderWeb({ roomid: this.roomid, spiderweb: name, value });
        },
        updateButton(name, value) {
            this.$store.direct.dispatch.rooms.setButton({ button: name, roomid: this.roomid, value });
        },
        updateKeyboard(name, value) {
            this.$store.direct.dispatch.rooms.setKeyboard({ roomid: this.roomid, keyboard: name, value });
        },
        sendRFID(name, value) {
            this.$store.direct.dispatch.rooms.setRFID({ rfid: name, roomid: this.roomid, value });
        },
        gameEvent(data) {
            this.$store.direct.dispatch.rooms.updateStatus({ roomid: this.roomid, data });
        }
    }
});
